import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { User } from '@angular/fire/auth';
import { AuthV2Service } from '@jfw-library/ecommerce/core';
import {
  PaymentChargeRequestType,
  PaymentChargeResponseType,
  PaymentRefIdRequestType,
  PaymentRefIdResponseType,
} from 'common-types';
import { Observable, firstValueFrom, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PaymentApiService {
  private PAYMENT_REST_API_SERVER = environment.payment_rest_api_server_url;

  // user: firebase.User | null = null;
  user: User | null = null;

  private reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  private authV2Service = inject(AuthV2Service);

  constructor(
    private httpClient: HttpClient // private afAuth: AngularFireAuth
  ) {
    this.authV2Service.authState$.subscribe((user) => {
      this.user = user;
    });
  }

  public apiGetRefId(
    requestData: PaymentRefIdRequestType
  ): Promise<PaymentRefIdResponseType> {
    console.log('apiGetRefId?');
    const apiUrl = `${this.PAYMENT_REST_API_SERVER}/getRefId`;

    console.log(apiUrl);

    return firstValueFrom<PaymentRefIdResponseType>(
      this.httpClient.post<PaymentRefIdResponseType>(apiUrl, requestData, {
        headers: this.reqHeader,
      })
    );
  }

  public apiCreateCharge(
    requestData: PaymentChargeRequestType
  ): Promise<PaymentChargeResponseType> {
    console.log('apiCreateCharge?');
    const apiUrl = `${this.PAYMENT_REST_API_SERVER}/createCharge`;

    console.log(apiUrl);

    return firstValueFrom<PaymentChargeResponseType>(
      this.httpClient.post<PaymentChargeResponseType>(apiUrl, requestData, {
        headers: this.reqHeader,
      })
    );
  }

  private handleError(err: HttpErrorResponse): Observable<never> {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage: string;
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Backend returned code ${err.status}: ${err.message}`;
    }
    console.error(err);
    return throwError(() => errorMessage);
  }

  // public apiEstimateTax(): Promise<number> {
  //   console.log('GETTING TAXES FROM API');
  //   const apiUrl = `${this.TAX_REST_API_SERVER}/estimateTax`;
  //   const data = {
  //     "referenceNumber": "junk 4/10/2023 2",
  //     "customerCode": "bert and ernie",
  //     "purchaseOrderNo": "MyPurchaseOrder",
  //     "amount": "100",
  //     "address": {
  //       "address1": "211 e fairway",
  //       "city": "Belleville",
  //       "state": "il",
  //       "zip": "62223"
  //     }
  //   }

  //   let promise = new Promise<number>((resolve, reject) => {
  //     firstValueFrom(this.httpClient.post(apiUrl, data))
  //       .then(
  //         res => {
  //           console.log("tax result", res)
  //           resolve(Math.round(Math.random()));
  //         }
  //       );
  //   });
  //   return promise;
  // }
}
